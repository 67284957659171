import React, { useRef, useState, useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';

// redux
import { useAppDispatch } from 'redux/store';
import { editQuizQuestion } from 'redux/actions/quizzes';

import NvFroala from 'froala/components/nv-froala';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { config } from '../../../config/pendo.config.json';

type Props = {
  className?: string;
};

const defaultQuestionTemplate = '<p class="froala-style-medium"><br /></p>';

const QuizQuestionContent = (props: Props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const mountedRef = useRef(false);
  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
  } = useContext(ProgressiveQuizContext);

  const {
    isStatement,
    isEditMode,
  } = useQuizModeAndQuestionType();

  const { currentQuestion } = useContext(QuestionContext);
  const angularServices = useContext(AngularServicesContext);
  const { registerSaving } = useContext(SavingRegistryContext);
  const [value, setValue] = useState(currentQuestion.questionText || defaultQuestionTemplate);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [imageSrc, setImageSrc] = useState();

  const handleChange = (html) => {
    const patch = {
      questionText: html,
    };
    setValue(html);
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    dispatch(editQuizQuestion({
      patch,
      id: currentQuestion.id,
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const handleOnClick = (e) => {
    if (e.target.tagName === 'IMG') {
      setShowFullScreen(true);
      setImageSrc(e.target.src);
    }
  };

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  const getPlaceholder = () => {
    if (isStatement) {
      return t.QUIZZES.STATEMENT.TYPE_STATEMENT();
    }
    return t.QUIZZES.QUESTION_PLACEHOLDER();
  };

  if (isEditMode) {
    return (
      <NvFroala
        allowToolbar
        value={value}
        minHeight={80}
        className={className}
        onChange={handleChange}
        placeholder={getPlaceholder()}
        dataQa={config.pendo.activities.progressiveQuiz.questionModal.questionTitle}
        dataQaId={`${config.pendo.activities.progressiveQuiz.questionModal.questionTitle}_${currentQuestion.id}`}
      />
    );
  }

  return (
    <React.Fragment>
      <ResponsivelyEmbeddedAngularHTML
        className={className}
        angularServices={angularServices}
        template={currentQuestion.questionText}
        onClick={handleOnClick}
      />
      <NvModal
        type={ModalType.FULL}
        show={showFullScreen}
        onClose={() => setShowFullScreen(false)}
        header={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.FULL_SCREEN()}
        body={(
          <div className='w-100 h-100'>
            <img className='w-100' src={imageSrc} alt='' />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default QuizQuestionContent;
